import uuid from "uuid-random";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";

const swal = window.swal;
const toastify = window.toastify;

const firebaseConfig = {
	apiKey: "AIzaSyAWm2kbqn0t4ABO26vk5M3K7zXaMaB2S1E",
	authDomain: "staticproject21.firebaseapp.com",
	databaseURL: "https://staticproject21-default-rtdb.firebaseio.com",
	projectId: "staticproject21",
	storageBucket: "staticproject21.appspot.com",
	messagingSenderId: "937020477951",
	appId: "1:937020477951:web:8107117e2c12d1b9a1d7ca"
}
firebase.initializeApp(firebaseConfig);

const db = firebase.database();

const kickText = document.createElement("p");
kickText.innerHTML = "<b> You have been kicked out for cheating! </b>";

const spamText = document.createElement("p");
spamText.innerHTML = "<b> You have been kicked out for spamming! </b>";

const fullText = document.createElement("p");
fullText.innerHTML = "<b> Unfortunatley the room is full, you need to wait for a few minutes for someone to leave! </b>";

let playerName;
let playerId;
let playerScore = 0;
let playerGuess = 0;
let playerChat = 0;
let guessInt, chatInt;

function playerEnter(player, id, setUserId, setQuest, setHint, setChats, setScores, setLeaders){
	let notOne = 0;
	let num = 0;
	playerName = player;
	playerId = id;

	db.ref("wordGuess/lastJoiner").set({
		value: 0
	});
	db.ref("wordGuess/lastJoiner").set({
		value: player
	});	
	db.ref(`wordGuess/players/${id}`).set({
		name: player,
		score: 0
	}).then(() => {
		setUserId(id);
	});
	db.ref("wordGuess/players").once("value", (s) => {
		if (s.numChildren() < 2){
			toastify({
				text: "Too less players, ask someone to join!",
		        toastBoxColor: "#c02e61",    
		        toastBoxTextColor: "white",    
		        toastBoxShadow: "none",    
		        toastBoxTextAlign: "center",    
		        toastWidth: "90vw",    
		        animationOut: "scale-up",    
		        position: "bottom left",    
		        toastCloseTimer: "2500"
		    });
		}
		else{
			toastify({
				text: `${s.numChildren()} player(s) currently joined!`,
		        toastBoxColor: "#c02e61",    
		        toastBoxTextColor: "white",    
		        toastBoxShadow: "none",    
		        toastBoxTextAlign: "center",    
		        toastWidth: "90vw",    
		        animationOut: "scale-up",    
		        position: "bottom left",    
		        toastCloseTimer: "2500"
		    });			
		}
	});
	db.ref("wordGuess/word").on("value", (s) => {
		setQuest(s.val().value);
		setHint(s.val().hint);
	});
	db.ref("wordGuess/lastGuesser").on("value", (s) => {
		let guesser;
		db.ref("wordGuess/lastGuesser").once("value", (s) => {
			guesser = s.val().value;
			if (notOne){
				toastify({
					text: `${guesser} guessed the word!`,
			        toastBoxColor: "#c02e61",    
			        toastBoxTextColor: "white",    
			        toastBoxShadow: "none",    
			        toastBoxTextAlign: "center",    
			        toastWidth: "90vw",    
			        animationOut: "scale-up",    
			        position: "bottom left",    
			        toastCloseTimer: "2500"
			    });	
			}
			else{
				++notOne;
			}
		});
	});
	db.ref("wordGuess/lastJoiner").on("value", (s) => {
		toastify({
			text: `${s.val().value} has joined the game!`,
	        toastBoxColor: "#c02e61",    
	        toastBoxTextColor: "white",    
	        toastBoxShadow: "none",    
	        toastBoxTextAlign: "center",    
	        toastWidth: "90vw",    
	        animationOut: "scale-up",    
	        position: "bottom left",    
	        toastCloseTimer: "2500"
	    });			
	});
	db.ref("wordGuess/lastLeaver").on("value", (s) => {
		if (num){
			toastify({
				text: `${s.val().value} has left the game!`,
		        toastBoxColor: "#c02e61",    
		        toastBoxTextColor: "white",    
		        toastBoxShadow: "none",    
		        toastBoxTextAlign: "center",    
		        toastWidth: "90vw",    
		        animationOut: "scale-up",    
		        position: "bottom left",    
		        toastCloseTimer: "2500"
		    });
		}
		else{
			++num;
		}		
	});
	db.ref("wordGuess/chats").orderByChild("timestamp").on("value", (s) => {
		let chats = [];
		s.forEach((v) => {
			chats.push(v.val().message);
		});
		if (s.numChildren() > 6){
			let n = 0;
			s.forEach((v) => {
				if (n === 0){
					db.ref(`wordGuess/chats/${v.key}`).remove();
				}
				++n;
			});
		}
		setChats(chats);
	});
	db.ref("wordGuess/players").on("value", (s) => {
		let playerScores = [];
		s.forEach((c) => {
			if (c.key === id){
				db.ref("wordGuess/leaderBoard").once("value", (s2) => {
					s2.forEach((c2) => {
						if (c.val().score > c2.val().score){
							db.ref(`wordGuess/leaderBoard/${c.key}`).set({
								name: c.val().name,
								score: c.val().score
							});
						}
					});
				});
			}
			playerScores.push({name: c.val().name, score: c.val().score, id: c.key});
		});
		setScores(playerScores);
	});
	db.ref(`wordGuess/leaderBoard`).orderByChild("score").on("value", (s) => {
		let leaderScores = [];
		s.forEach((c) => {
			leaderScores.push({name: c.val().name, score: c.val().score, id: c.key});
		});
		if (s.numChildren() > 3){
			let n = 0;
			s.forEach((v) => {
				if (n === 0){
					db.ref(`wordGuess/leaderBoard/${v.key}`).remove();
				}
				++n;
			});
		}
		setLeaders(leaderScores);
	});
	db.ref(`wordGuess/players/${id}`).onDisconnect().remove();
	db.ref("wordGuess/lastLeaver").onDisconnect().set({
		value: 0
	});
	db.ref("wordGuess/lastLeaver").onDisconnect().set({
		value: player
	});
}

function playerGuessed(setQuest){
	if (playerGuess < 2){
		let val;
		db.ref(`wordGuess/players/${playerId}`).once("value", (s) => {
			val = s.val().name;
			playerScore = s.val().score + 1;
			db.ref("wordGuess/lastGuesser").set({
				value: 0
			});
			db.ref("wordGuess/lastGuesser").set({
				value: val
			});
			genWord();
		});
		db.ref(`wordGuess/players/${playerId}`).set({
			name: playerName,
			score: playerScore
		});
		guessInt = setTimeout(() => {
			playerGuess = 0;
		}, 10000);
	}
	else{
		swal({
			title: "Congratulations!",
			content: kickText,
			button: "I'm sorry!",
			closeOnClickOutside: false
		});
		db.goOffline();
	}
}

function playerData(data){
	if (playerChat < 4){
		if (data){
			db.ref(`wordGuess/chats/${uuid()}`).set({
				message: `${playerName}: ${data.slice(0, 25)}`,
				timestamp: firebase.database.ServerValue.TIMESTAMP
			});
		}
		chatInt = setTimeout(() => {
			playerChat = 0;
		}, 10000);		
		++playerChat;
	}
	else{
		toastify({
			text: `Please wait a few seconds before another guess! (to prevent spam)`,
	        toastBoxColor: "#c02e61",    
	        toastBoxTextColor: "white",    
	        toastBoxShadow: "none",    
	        toastBoxTextAlign: "center",    
	        toastWidth: "90vw",    
	        animationOut: "scale-up",    
	        position: "bottom left",    
	        toastCloseTimer: "2500"
		});
		chatInt = setTimeout(() => {
			playerChat = 0;
		}, 10000);
	}
}

function submitForm(guess, pquest, pguessed, psendMessage, setGuess){
	if (guess.toLowerCase() === pquest.toLowerCase()){
		pguessed();
	}
	else{
		toastify({
			text: "Not quite. Try again!",
	        toastBoxColor: "#c02e61",    
	        toastBoxTextColor: "white",    
	        toastBoxShadow: "none",    
	        toastBoxTextAlign: "center",    
	        toastWidth: "90vw",    
	        animationOut: "scale-up",    
	        position: "bottom left",    
	        toastCloseTimer: "2500"
	    });
	}
	psendMessage(guess);
	setGuess("");	
}

function genWord(){
	axios.get("https://random-word-form.herokuapp.com/random/noun").then((resa) => {
		axios.get(`https://api.allorigins.win/get?url=${encodeURIComponent(`https://api.dictionaryapi.dev/api/v2/entries/en_US/${resa.data[0]}`)}`).then((resb) => {
			let data = JSON.parse(resb.data.contents);
			try {
				let def = (data[0].meanings[0] !== undefined ? data[0].meanings[0].definitions[0].definition : data[0].definitions[0].definition);
				db.ref("wordGuess/word").set({
					value: resa.data[0],
					hint: def
				});
			}
			catch(err){
				genWord();
			}
		});			
	});
}

export {playerEnter, playerGuessed, playerData, submitForm};